<template>
  <main class="about">
    <Breadcrumb></Breadcrumb>

    <section class="">
      <PageHeader
        title="About the University of Georgia"
        :video="require(`@/assets/video/blc.mp4`)"
        :backgroundImage="require(`@/assets/img/blc-poster.jpg`)"
        backgroundColor="transparent"
        textColor="white"
        overlay="medium"
        align="center"
        size="lg"
      >
        <p>
          At the University of Georgia, our rich tradition begins with providing an exceptional education and pursuing a brighter future for our world.
        </p>
      </PageHeader>
    </section>

    <section>
        <Section class="text-white bg-red text-center" type="text">
          <div data-aos="fade-up">
            <h3 class="sr-only">Leading Public Higher Education</h3>
            <img
              class="mb-4"
              :src="require(`@/assets/img/birthplace-logo-white.png`)"
              alt="The Birthplace of Public Higher Education in America"
              style="width: 425px;"
            />
            <p>
              As the birthplace of public higher education in America, the University of Georgia has inspired generations of entrepreneurs, researchers, and citizens to change the world since its founding in 1785.
            </p>

            <p>
              Driven by our land&#45; and sea&#45;grant mission, we are dedicated to serving every corner of the state of Georgia. The motto, “to teach, to serve, and to inquire into the nature of things,” reflects the University’s commitment to enriching the state and nation’s intellectual, cultural, and environmental legacy.
            </p>

            <Btn color="white" href="/about/mission">Mission Statement</Btn>
          </div>
        </Section>
    </section>

    <!-- <section>
      <div data-aos="fade">
        <Section
          type="inset"
          imgSide="left"
          imgURL="academic-excellence/36001-039.jpg"
        >
          <h3>Home to the Best and Brightest</h3>
          <p>
            A powerhouse in academics, the University of Georgia offers a
            top-rated honor’s college, hands-on learning opportunities, and
            small class sizes to give our students their best chance of
            success, both on and beyond the campus.
          </p>
          <Btn href="/about/academic-excellence" color="dark" outline
            >Academic Excellence</Btn
          >
        </Section>
      </div>
    </section> -->

    <!-- <section class="bg-red text-white">
      <Section type="text">
        <div data-aos="fade-up">
          <h3>Diversity and Inclusion</h3>
          <p>
            A diverse community is essential to the growth and success of our
            community. We are continuously developing initiatives to encourage
            an inclusive culture, including:
          </p>
          <ul>
            <li>
              The
              <a
                class="text-white text-decoration-underline"
                href="https://dae.uga.edu/initiatives/all-georgia-program/"
                >ALL Georgia Program</a
              >, which focuses on students from rural parts of the state.
            </li>
            <li>
              The
              <a
                class="text-white text-decoration-underline"
                href="https://diversity.uga.edu/partnerships/gaame_scholars_program/"
                >Georgia African American Male Experience</a
              >, which provides support and mentoring.
            </li>
            <li>
              The
              <a
                class="text-white text-decoration-underline"
                href="https://seercenter.uga.edu/research/current-projects/delta-project/"
                >DeLTA project</a
              >, which promotes diversity in STEM fields.
            </li>
          </ul>
          <p>
            For the ninth year in a row, the University of Georgia was
            recognized for our commitment through a national Higher Education
            Excellence in Diversity Award.
          </p>
        </div>
        <Btn href="https://diversity.uga.edu/" color="white" outline
          >Office of Institutional Diversity</Btn
        >
      </Section>
    </section> -->

    <section>
      <Section
        class="text-white"
        type="standard"
        imgSide="left"
        imgURL="value/cortona-students-in-square.jpg"
        color="bg-grey bg-diagonal--dark"
        :caption="`UGA’s study abroad program in Cortona, Italy`"
      >
        <div data-aos="fade">
          <h3>Why Choose UGA?</h3>
          <ul>
            <li>
              <strong>95% employed or in graduate school within six months.</strong>
            </li>
            <li>
              <strong>Our 17:1 student/faculty ratio</strong> improves student learning and success by ensuring personalized attention.
            </li>
            <li>
              <strong>Opportunities abound.</strong> 800+ student organizations.
              130+ study abroad programs. 142+ fields of study.
            </li>
            <li>
              <strong>Earn an undergraduate and master’s degree</strong> in just five years. Earn an advanced degree faster and save money.
            </li>
            <li>
              <strong>Leader in experiential learning.</strong> Get hands-on experiences to prepare for your future
            </li>
            <li>
              <strong>UGA’s main campus</strong> is nestled on the border of downtown Athens, Georgia. Considered one of the best college towns in America, Athens showcases a vibrant culture of music, art, and food.
            </li>
          </ul>
            <div class="mb-3">
              <Btn href="https://visit.uga.edu" color="white" size="sm" outline>
                Schedule a Visit
              </Btn>
            </div>
            <div>
              <Btn href="/about/value" color="white" size="sm" outline>
                Learn More
                <span class="visually-hidden">
                  about why UGA is a top value
                </span>
              </Btn>
            </div>
        </div>
      </Section>
    </section>

      <div class="row g-0">
        <div class="col-md-4 border-right">
          <Figure
            prefix="#"
            :endAmount="11"
            :startAmount="0"
            subheading="Top Public Universities in the U.S."
            citation="Niche"
            backgroundColor="red"
            textColor="white"
          ></Figure>
        </div>
        <div class="col-md-4 border-right">
          <Figure
            prefix="#"
            :endAmount="11"
            :startAmount="50"
            subheading="Public School for Financial Aid"
            citation="Princeton Review"
            backgroundColor="red"
            textColor="white"
          ></Figure>
        </div>
        <div class="col-md-4">
          <Figure
            prefix="Top "
            :endAmount="20"
            :startAmount="50"
            subheading="Best Public Colleges"
            citation="U.S. News &amp; World Report"
            backgroundColor="red"
            textColor="white"
          ></Figure>
        </div>
      </div>

    <!-- <section>
      <Section
        class="text-dark"
        type="standard"
        imgSide="right"
        imgURL="academic-excellence/36001-039.jpg"
        color="bg-white bg-diagonal--dark"
        :caption="`UGA’s study abroad program in Cortona, Italy`"
      >
        <div data-aos="fade">
          <h3>Unleashing Your Potential</h3>
            <p>
              The University of Georgia offers a top-rated honors college, hands-on learning opportunities, and small class sizes to give our students their best chance of success, both on and beyond the campus.
            </p>
            <Btn color="black" href="/about/academic-excellence" outline
              >Academic Excellence</Btn
            >
        </div>
      </Section>
    </section>

      <div
        class="row"
        id="imgBackground"
        style="background: linear-gradient(to left top, rgba(255,255,255,1) 50%, rgba(186, 12,47,1) 50%, rgba(186, 12, 47, 1) 100%);"
      >
        <Section
          data-aos="flip-up"
          data-aos-offset="300"
          type="imgBackground"
          textAlign="left"
          imgURL="research/37824-002.jpg"
          heading="Discoveries that Change Lives"
        >
          <h3>Discoveries that Change Lives</h3>

          <p>
            The University of Georgia is leading research initiatives in global
            health and medicine, education, agriculture and more. We engage our
            students and faculty in human-centered research that changes the
            world.
          </p>

          <p>
            In our relentless pursuit for change, we have been a Top 5
            university for new products to market for almost a decade. We are
            committed to a better future.
          </p>
          <Btn color="primary" href="/about/research" size="sm">Learn More</Btn>
        </Section>
      </div> -->

    <Section
      type="imgBackground"
      imgSide="center"
      textAlign="right"
      imgURL="academic-excellence/36001-039.jpg"
      heading="Research and Innovation"
      :style="{
        backgroundPosition: 'bottom center'
      }"
    >
        <h3>Unleashing Your Potential</h3>

        <p class="text-end">
          The University of Georgia offers a top-rated honors college, hands-on learning opportunities, and small class sizes to give our students their best chance of success, both on and beyond the campus.
        </p>
        <Btn color="primary" href="/about/academic-excellence" size="md">Academic Excellence</Btn>
    </Section>

    <!-- placeholder style element -->
    <hr style="margin:0;padding:0;color:white;height:5px;">

    <Section
      type="imgBackground"
      imgSide="center"
      textAlign="left"
      imgURL="research/37824-002.jpg"
      heading="Research and Innovation"
      :style="{
        backgroundPosition: 'bottom center',
      }"
    >
        <h3>Research and Innovation</h3>

        <p>
          The University of Georgia leads interdisciplinary research initiatives in global health and medicine, education, agriculture, and more. We engage our students and faculty in research aimed at improving the world.
        </p>

        <p>
          With a focus on creating innovative solutions through our research, we have been a Top 5 university for new products to market for almost a decade.
        </p>
        <Btn color="primary" href="/about/research" size="md">Research at UGA</Btn>
    </Section>

    <!-- <section>
      <Section
        type="imgBackground"
        class="text-center"
        imgURL="homepage/35501-081_edit.jpg"
      >
        <figure data-aos="zoom-in-up" class="mb-0">
          <blockquote class="blockquote">
            <p>
              “The University of Georgia’s investments in faculty and student
              success initiatives have helped make our institution a national
              leader in public higher education. Our 2025 Strategic Plan builds
              on this strong foundation to further enhance our teaching and
              learning environment.”
            </p>
          </blockquote>
          <figcaption class="blockquote-footer text-light">
            S. Jack Hu, Senior Vice President for Academic Affairs and Provost
          </figcaption>
        </figure>
      </Section>
    </section> -->

    <section style="margin: 5rem 0">
      <div class="container">
        <div class="row">
          <div class="col-md-6" data-aos="fade-in">
            <div class="card border-white">
              <img
                class="card-img-top"
                :src="require(`@/assets/img/academic-excellence/37104-038.jpg`)"
                alt=""
              />
              <div class="card-body">
                <h2 class="card-title">
                  National and International Student Achievement
                </h2>
                <div class="card-text">
                  <p>
                    UGA provides a world-class learning environment and opportunities for high-achieving students. With 28 Rhodes Scholars and 143 Fulbright scholars, we are one of the top public university producers of both student awards in the nation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" data-aos="fade-in">
            <div class="card border-white">
              <img
                class="card-img-top"
                :src="require(`@/assets/img/research/world-class-faculty2.jpg`)"
                alt=""
              />
              <div class="card-body">
                <h2 class="card-title">World Class Faculty</h2>
                <div class="card-text">
                  <p>
                    Faculty at the University of Georgia are attaining some of the most prestigious and competitive awards on both a national and international level. In the last 12 years, three UGA faculty members have been named SEC Professor of the Year, including 2024 recipient Jenna Jambeck, who was also awarded a MacArthur Genius Grant in 2022. And in 2024 alone, eight UGA researchers were named Senior Members of the National Academy of Inventors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-primary py-5 text-white text-center">
      <div class="container">
        <h2>Impact Through Service</h2>
        <p>
          The University is committed to the future of our state, our nation,
          and our world.
        </p>
      </div>
      <div class="row g-0">
        <div class="col-md-4 border-right">
          <Figure
            prefix="$"
            suffix="B"
            :endAmount="8.4"
            decimals="1"
            :startAmount="0"
            subheading="impact on the state of Georgia"
            citation="FY 2024"
            backgroundColor="red"
            textColor="white"
          ></Figure>
        </div>
        <div class="col-md-4">
          <Figure
            prefix=""
            suffix=" miles"
            :endAmount="40"
            :startAmount="0"
            subheading="Every Georgian is within 40 miles of a UGA resource"
            backgroundColor="red"
            textColor="white"
          ></Figure>
        </div>
        <div class="col-md-4 border-right">
          <Figure
            suffix="+"
            :endAmount="200"
            :startAmount="0"
            subheading="companies launched based on UGA research"
            citation="FY 2021"
            backgroundColor="red"
            textColor="white"
          ></Figure>
        </div>
      </div>
      <Btn
        color="white"
        href="https://wearegeorgia.uga.edu"
        size="lg"
        >UGA’s impact on the state of Georgia</Btn
      >
    </section>

    <section class="" style="margin: 5rem 0">
      <div class="container">
        <div class="row g-5">
          <div class="col-md-6" data-aos="fade-in">
            <div class="card border-white">
              <img
                class="card-img-top"
                :src="require(`@/assets/img/history/36987-004.jpg`)"
                alt=""
              />
              <div class="card-body">
                <h2 class="card-title">A History of Excellence</h2>
                <div class="card-text">
                  <p>
                    Since 1785, the University of Georgia has evolved and adapted to new experiences, new trajectories, and new traditions, but has held steady in our commitment to world-class education and research.
                  </p>

                  <Btn color="dark" href="/about/history-and-traditions" outline
                    >History &amp; Traditions</Btn
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" data-aos="fade-in">
            <div class="card border-white">
              <img
                class="card-img-top"
                :src="require(`@/assets/img/value/38793-159.jpg`)"
                alt=""
              />
              <div class="card-body">
                <h2 class="card-title">Beyond the Campus</h2>
                <div class="card-text">
                  <p>
                    The University of Georgia is committed to providing opportunities. Through hands-on learning, innovative initiatives, and study-away programs, our students are preparing for extraordinary lives.
                  </p>
                  <Btn color="dark" href="https://stories.uga.edu/real-world-learning" outline
                    >Explore Opportunities</Btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Section
      type="imgBackground"
      imgSide="center"
      textAlign="center"
      imgURL="homepage/38987-110.jpg"
      heading="Home of the Georgia Bulldogs!"
      :style="{
        backgroundPosition: 'bottom center',
      }"
    >
      <div class="text-center" data-aos="fade-zoom-in">
        <h3>Home of the Georgia Bulldogs!</h3>
        <p>
          The Georgia Bulldogs have had some of the most decorated teams and athletes in college athletics. We have brought home over 40 National Championship trophies, and Bulldog athletes have represented UGA and their home nation more than 140 times in the Olympic Games and Paralympics.
        </p>
        <Btn color="white" href="https://georgiadogs.com/" outline
          >University of Georgia Athletics</Btn
        >
      </div>
    </Section>
  </main>
</template>

<script>
// @ is an alias to /src
// import EmergencyBanner from '@/components/EmergencyBanner.vue';
import Btn from "@/components/Btn.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageHeader from "@/components/PageHeader.vue";
import Figure from "@/components/Figure.vue";
import Section from "@/components/Section.vue";
// import ArrowSection from "@/components/ArrowSection.vue";

export default {
  name: "About",
  metaInfo: {
    title: "About the University of Georgia",
    description:
      "UGA is providing endless possibilities for our community. Founded in 1785, we are the birthplace of public higher education in America.",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  methods: {
    handleSearch(e) {
      this.$router.push({ name: "Directory", query: { q: e } });
    },
  },
  data() {
    return {};
  },
  components: {
    Btn,
    PageHeader,
    Breadcrumb,
    Figure,
    Section,
    // ArrowSection
  },
};
</script>

<style scoped lang="scss">
#imgBackground {
  margin: 0 auto;
  padding: 2rem 1rem;
}

section li {
  margin-bottom: 0.8rem;
}

@include media-breakpoint-up(lg) {
  #imgBackground {
    padding: 6rem 8rem;
  }
}
</style>
